<template>
  <v-skeleton-loader
    v-if="isLoading"
    class="mx-auto"
    max-width="800"
    type="card"
  ></v-skeleton-loader>
  <v-card v-else outlined>
    <v-card-subtitle>{{ getRequestTypeLabel(request.request.requestType) }}</v-card-subtitle>
    <v-card-title class="pt-0 text-h4">
      {{ request.request.rawsDocumentNumber }}
      <v-spacer></v-spacer>
      <request-status-chip
        v-if="request"
        :status="request.request.rawsStatus"
      ></request-status-chip>
    </v-card-title>
    <v-card-text>
      <v-alert
        class="alert-comment"
        v-if="request && request.request.rawsStatus === 'Applicant'"
        color="rgba(236, 142, 106, 1)"
        outlined
        text
        dense
      >
        <span class="text-subtitle-1 font-weight-medium">Comment from {{request.request.approver.longAccount}}</span>
        <br />
        {{ request.request.rejectReason }}
      </v-alert>
      <v-alert
        class="alert-comment"
        v-if="request && request.request.rawsStatus === 'Canceled'"
        color="rgb(211, 47, 47, 1)"
        outlined
        text
        dense
      >
        <span class="text-subtitle-1 font-weight-medium">Reject reason</span>
        <br />
        <div>{{ request.request.cancelReason }}</div>
      </v-alert>
      <v-alert
        class="alert-comment"
        v-if="request && request.request.rawsStatus === 'Rejected'"
        color="rgb(211, 47, 47, 1)"
        outlined
        text
        dense
      >
        <span class="text-subtitle-1 font-weight-medium">Reject reason</span>
        <br />
        <div>{{ request.request.rejectReason }}</div>
      </v-alert>
      <request-form
        v-if="request"
        :isDisplayMode="
          request.request.rawsStatus === 'Applicant' ? false : true
        "
        :isResubmit="request.request.rawsStatus === 'Applicant' ? true : false"
        :request="request"
      ></request-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'pinia'
import { mapActions } from 'pinia'
import { useRequestStore } from '@/store/request'
import { useUserStore } from '@/store/user'

import RequestForm from '@/components/RequestForm.vue'
import RequestStatusChip from '@/components/RequestStatusChip.vue'
import RequestMixin from '@/mixins/request.mixin'

export default {
  name: 'RequestDetails',
  components: { RequestForm, RequestStatusChip },
  mixins: [RequestMixin],
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState(useRequestStore, ['request']),
    ...mapState(useUserStore, ['user'])
  },
  methods: {
    ...mapActions(useRequestStore, ['fetchRequestAsync'])
  },
  async mounted() {
    this.isLoading = true
    
    await this.fetchRequestAsync(this.$route.params.rawsDocumentNumber).then(
      (res) => {
        this.isLoading = false

        if (!res.success) {
          switch (res.error.response.status) {
            case 404: {
              this.$router.push({ name: 'not-found' })
              break
            }
            case 403: {
              this.$router.push({ name: 'forbidden' })
              break
            }
            default: {
            }
          }
        } else {
          
          if (this.$route.name === 'show-approval') {
            //The current route is show-approval, but this sponsor submitter user actually wanted show-request
            if ((this.request.request.sponsorEmployeeNumber === this.user.employeeNumber)  && (this.request.request.rawsStatus === 'L4 Manager Approval' || this.request.request.rawsStatus === 'Verification and  Application Assignment')) {
              this.$router.push({ name: 'show-request' })
            }
            else if (this.request.request.rawsPersonInCharge !== this.user.employeeNumber) {
              this.$router.push({ name: 'forbidden' })
            }
          }
          else if (this.$route.name === 'show-request' ) {
            //The current route is show-request, but this personInCharge user actually wanted show-approval
            if (this.request.request.rawsPersonInCharge === this.user.employeeNumber && (this.request.request.rawsStatus === 'L4 Manager Approval' || this.request.request.rawsStatus === 'Verification and  Application Assignment')) {
              this.$router.push({ name: 'show-approval' })
            }
            else if (this.request.request.applicantEmployeeNumber !== this.user.employeeNumber && this.request.request.sponsorEmployeeNumber !== this.user.employeeNumber){
              this.$router.push({ name: 'forbidden' })
            }
          }
          
        }
      }
    )
  }
}
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: thin solid rgba(255, 234, 226, 0.75);
}

.theme--light.v-card {
  color: #192b40;
}

.theme--light.v-card > .v-card__subtitle {
  color: #192b40;
}

.alert-comment {
  overflow-wrap: anywhere !important;
}
</style>
