import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"800","type":"card"}}):_c(VCard,{attrs:{"outlined":""}},[_c(VCardSubtitle,[_vm._v(_vm._s(_vm.getRequestTypeLabel(_vm.request.request.requestType)))]),_c(VCardTitle,{staticClass:"pt-0 text-h4"},[_vm._v(" "+_vm._s(_vm.request.request.rawsDocumentNumber)+" "),_c(VSpacer),(_vm.request)?_c('request-status-chip',{attrs:{"status":_vm.request.request.rawsStatus}}):_vm._e()],1),_c(VCardText,[(_vm.request && _vm.request.request.rawsStatus === 'Applicant')?_c(VAlert,{staticClass:"alert-comment",attrs:{"color":"rgba(236, 142, 106, 1)","outlined":"","text":"","dense":""}},[_c('span',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v("Comment from "+_vm._s(_vm.request.request.approver.longAccount))]),_c('br'),_vm._v(" "+_vm._s(_vm.request.request.rejectReason)+" ")]):_vm._e(),(_vm.request && _vm.request.request.rawsStatus === 'Canceled')?_c(VAlert,{staticClass:"alert-comment",attrs:{"color":"rgb(211, 47, 47, 1)","outlined":"","text":"","dense":""}},[_c('span',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v("Reject reason")]),_c('br'),_c('div',[_vm._v(_vm._s(_vm.request.request.cancelReason))])]):_vm._e(),(_vm.request && _vm.request.request.rawsStatus === 'Rejected')?_c(VAlert,{staticClass:"alert-comment",attrs:{"color":"rgb(211, 47, 47, 1)","outlined":"","text":"","dense":""}},[_c('span',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v("Reject reason")]),_c('br'),_c('div',[_vm._v(_vm._s(_vm.request.request.rejectReason))])]):_vm._e(),(_vm.request)?_c('request-form',{attrs:{"isDisplayMode":_vm.request.request.rawsStatus === 'Applicant' ? false : true,"isResubmit":_vm.request.request.rawsStatus === 'Applicant' ? true : false,"request":_vm.request}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }