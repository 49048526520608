export default {
  data() {
    return {
      requestTypes: [
        {
          value: 'ACCOUNT_CREATION',
          label: 'Account Creation'
        },
        {
          value: 'ACCOUNT_EXPIRY_EXTENSION',
          label: 'Account Expiry Extension'
        },
        {
          value: 'ACCOUNT_REACTIVATION',
          label: 'Account Reactivation'
        },
        {
          value: 'SERVICE_SUBSCRIPTION',
          label: 'Service Subscription'
        }
      ],
      rawsStatuses: [
        {
          value: 'L4 Manager Approval',
          color: 'rgba(236, 142, 106, 1)'
        },
        {
          value: 'In Progress',
          color: 'rgba(236, 142, 106, 1)'
        },
        {
          value: 'Verification and Application Assignment',
          color: 'rgba(97, 117, 140, 1)'
        },
        {
          value: 'Manager Approval',
          color: 'rgba(236, 142, 106, 1)'
        },
        {
          value: 'Service Owner Approval',
          color: 'rgba(236, 142, 106, 1)'
        },
        {
          value: 'Applicant',
          color: 'rgba(236, 142, 106, 1)'
        },
        {
          value: 'Okta Account Creation',
          color: 'rgba(97, 117, 140, 1)'
        },
        {
          value: 'Account Expiry Extension',
          color: 'rgba(97, 117, 140, 1)'
        },
        {
          value: 'Account Reactivation',
          color: 'rgba(97, 117, 140, 1)'
        },
        {
          value: 'Service Subscription',
          color: 'rgba(97, 117, 140, 1)'
        },
        {
          value: 'Rejected',
          color: 'rgb(211, 47, 47, 1)'
        },
        {
          value: 'Canceled',
          color: 'rgb(211, 47, 47, 1)'
        },
        {
          value: 'Completed',
          color: 'success'
        }
      ]
    }
  },
  methods: {
    getRequestTypeLabel(val) {
      let requestType = _.find(this.requestTypes, (x) => x.value === val)
      return requestType ? requestType.label : '' 
    }
  }
}