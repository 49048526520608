<template>
  <v-chip :color="chip.color" outlined small>
    {{ chip.value }}
  </v-chip>
</template>

<script>
import RequestMixin from '@/mixins/request.mixin'

export default {
  name: 'RequestStatusChip',
  mixins: [RequestMixin],
  props: {
    status: { type: String }
  },
  data() {
    return {}
  },
  computed: {
    chip() {
      return _.find(this.rawsStatuses, (x) => x.value === this.status)
    }
  }
}
</script>