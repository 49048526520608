import { defineStore } from 'pinia'
import ApiService from '@/service/api.service.js'
import requestTypes from './requestTypes.js'
import { useUserStore } from '@/store/user'

export const useApprovalStore = defineStore('approval', {
  state: () => ({
    approval: null,
    selectedType: requestTypes.find((x) => x.name === 'ACCOUNT_CREATION'),
    approvals: [],
    totalApprovals: 0,
    approvalHeaders: [
      {
        text: 'Request Type',
        value: 'requestType'
      },
      {
        text: 'Document Number',
        value: 'rawsDocumentNumber'
      },
      {
        text: 'Created At',
        value: 'createdAt'
      },
      {
        text: 'Updated At',
        value: 'updatedAt'
      },
      {
        text: 'Requester',
        value: 'applicant.longAccount'
      }
    ],
    approvalHeadersWidget: [
      {
        text: 'Request Type',
        value: 'requestType'
      },
      {
        text: 'Document Number',
        value: 'rawsDocumentNumber'
      },
      {
        text: 'Requester',
        value: 'applicant.longAccount'
      }
    ],
    requestSnackbar: {
      text: null,
      color: null,
      value: false
    }
  }),
  getters: {
    getApproval(state) {
      return state.approval
    },
    getApprovals(state) {
      return state.approvals
    }
  },
  actions: {
    async fetchApprovalAsync(rawsDocumentNumber) {
      ApiService.customRequest({
        method: 'get',
        url: `/Requests/${rawsDocumentNumber}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.approval = result.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async fetchApprovalsAsync(options) {
      let orderBy = []
      options.sortBy.map((obj, i) => {
        if (options.sortDesc[i]) {
          orderBy.push(`${obj} desc`)
        } else {
          orderBy.push(`${obj} asc`)
        }
      })

      const userStore = useUserStore()

      let params = `perPage=${options.itemsPerPage}&page=${options.page}&rawsPersonInCharge=${userStore.user.employeeNumber}`
      if (orderBy.length > 0) {
        params += `&orderBy=${orderBy}`
      }

      ApiService.customRequest({
        method: 'get',
        url: `/Requests?${params}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          let pagination = JSON.parse(res.headers['x-pagination'])
          this.totalApprovals = pagination.TotalItemCount
          this.approvals = res.data
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.requestSnackbar = {
              text: `Failed to update approval list. Error: ${error.message}`,
              color: 'red darken-2',
              value: true
            }
          }
        })
    },
    async resolveApprovalAsync(id, data) {
      let action = null

      switch (data.action) {
        case 'Approve':
          action = 'approved'
          break
        case 'Reject':
          action = 'rejected'
          break
        case 'Cancel':
          action = 'canceled'
      }

      let strResolveUrl = data.type === 'SERVICE_SUBSCRIPTION' ? `/ServiceSubscriptionRequests/Resolve/${id}` : `/Requests/${id}/rawsStatus`

      return ApiService.customRequest({
        method: 'post',
        url: strResolveUrl, 
        headers: { 'Content-Type': 'application/json' }, 
        data
      })
        .then((result) => {
          this.approval = result.data
          this.requestSnackbar = {
            text: `Request ${action} successfully.`,
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else if (error.response.status == 400) {
            this.requestSnackbar = {
              text: `${error.response.data.message}`,
              color: 'red darken-2',
              value: true
            }
          } else {
            this.requestSnackbar = {
              text: `${error.message}`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    }
  }
})
