import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[(_vm.isLoading)?_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"800","type":"card"}},[_c(VSnackbar,{attrs:{"color":_vm.requestSnackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.requestSnackbar.value = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}],null,false,1904327725),model:{value:(_vm.requestSnackbar.value),callback:function ($$v) {_vm.$set(_vm.requestSnackbar, "value", $$v)},expression:"requestSnackbar.value"}},[_vm._v(" "+_vm._s(_vm.requestSnackbar.text)+" ")])],1):(
      !_vm.isLoading &&
      this.approval &&
      ((this.resourceType === 'approval' && (this.approval.rawsStatus === 'L4 Manager Approval' || this.approval.rawsStatus === 'Verification and  Application Assignment' ) )
      || (this.resourceType === 'request' && this.approval.rawsStatus === 'Applicant')
      )
    )?_c(VCard,{attrs:{"flat":"","floating":""}},[_c(VCardText,[_c(VForm,{ref:"comment",attrs:{"lazy-validation":""}},[_c(VTextarea,{attrs:{"filled":"","rounded":"","dense":"","rules":_vm.rules,"label":"Comment*","counter":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1),(_vm.resourceType === 'approval')?_c(VCardActions,[_c(VBtn,{attrs:{"color":"secondary","depressed":"","loading":_vm.rejectingWorkflow,"disabled":_vm.approvingWorkflow},on:{"click":function($event){return _vm.validate('Reject')}}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("mdi-close")]),_vm._v(" Reject ")],1),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","depressed":"","loading":_vm.approvingWorkflow,"disabled":_vm.rejectingWorkflow},on:{"click":function($event){return _vm.validate('Approve')}}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("mdi-check")]),_vm._v(" Approve ")],1)],1):_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","depressed":"","loading":_vm.cancelingWorkflow},on:{"click":function($event){return _vm.validate('Cancel')}}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("mdi-close")]),_vm._v(" Cancel ")],1)],1)],1):_vm._e(),_c(VSnackbar,{attrs:{"color":_vm.requestSnackbar.color,"vertical":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.requestSnackbar.value = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.requestSnackbar.value),callback:function ($$v) {_vm.$set(_vm.requestSnackbar, "value", $$v)},expression:"requestSnackbar.value"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.requestSnackbar.text)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }