<template>
  <v-flex>
    <v-skeleton-loader
      v-if="isLoading"
      class="mx-auto"
      max-width="800"
      type="card"
    >
      <v-snackbar
        v-model="requestSnackbar.value"
        :color="requestSnackbar.color"
      >
        {{ requestSnackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="requestSnackbar.value = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-skeleton-loader>
    <v-card
      flat
      floating
      v-else-if="
        !isLoading &&
        this.approval &&
        ((this.resourceType === 'approval' && (this.approval.rawsStatus === 'L4 Manager Approval' || this.approval.rawsStatus === 'Verification and  Application Assignment' ) )
        || (this.resourceType === 'request' && this.approval.rawsStatus === 'Applicant')
        )
      "
    >
      <v-card-text>
        <v-form ref="comment" lazy-validation>
          <v-textarea
            filled
            rounded
            dense
            :rules="rules"
            v-model="comment"
            label="Comment*"
            counter
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="resourceType === 'approval'">
        <v-btn
          color="secondary"
          depressed
          :loading="rejectingWorkflow"
          :disabled="approvingWorkflow"
          @click="validate('Reject')"
        >
          <v-icon left dark>mdi-close</v-icon>
          Reject
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          :loading="approvingWorkflow"
          :disabled="rejectingWorkflow"
          @click="validate('Approve')"
        >
          <v-icon left dark>mdi-check</v-icon>
          Approve
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          :loading="cancelingWorkflow"
          @click="validate('Cancel')"
        >
          <v-icon left dark>mdi-close</v-icon>
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="requestSnackbar.value" :color="requestSnackbar.color" vertical>
      <span v-html="requestSnackbar.text"></span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="requestSnackbar.value = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-flex>
</template>

<script>
import { mapState } from 'pinia'
import { mapActions } from 'pinia'
import { useApprovalStore } from '@/store/approval'
import { useUserStore } from '@/store/user'

export default {
  name: 'ApprovalToolbar',
  props: {
    resourceType: { type: String }
  },
  data() {
    return {
      comment: null,
      isLoading: true,
      approvingWorkflow: false,
      rejectingWorkflow: false,
      cancelingWorkflow: false,
      timeout: 3000,
      rules: [
        (v) => !!v || 'This is a required field',
        (v) => !v || !/^\s*$/.test(v.trim()) || 'This is a required field.',
        (v) => (!!v && v.length <= 200) || 'Maximum 200 characters.'
      ],
      maxlength: 200
    }
  },
  computed: {
    ...mapState(useUserStore, ['user']),
    ...mapState(useApprovalStore, [
      'approval',
      'selectedType',
      'requestSnackbar'
    ])
  },
  methods: {
    async validate(action) {
      if (this.comment === null || this.comment.trim().length === 0 || this.comment.length > 200) {
        this.$refs.comment.validate()
      } else {
        await this.resolve(action)
      }
    },
    async resolve(action) {
      await this.fetchApprovalAsync(this.$route.params.rawsDocumentNumber)

      switch(action) {
        case 'Approve':
          this.approvingWorkflow = true
          break
        case 'Reject':
          this.rejectingWorkflow = true
          break
        case 'Cancel':
          this.cancelingWorkflow = true
          break
        default:
          break
      }

      let args = {
        resourceId: this.approval.rawsId,
        action: action,
        comment: this.comment,
        actor: _.trim(this.user.employeeNumber),
        type: this.approval.requestType
        // actor: _.trim(this.approval.rawsPersonInCharge) // For testing only
      }

      await this.resolveApprovalAsync(this.approval.requestId, args)
        .then(
          (res) => {
            switch(args.action) {
              case 'Approve':
                this.approvingWorkflow = false
                break
              case 'Reject':
                this.rejectingWorkflow = false
                break
              case 'Cancel':
                this.cancelingWorkflow = false
                break
              default:
                break
            }
            
            if (res) {
              if (args.action === 'Cancel') {
                this.$router.push({ name: 'list-requests' })
              } else {
                this.$router.push({ name: 'list-approvals' })
              }
            } else {
              this.fetchApprovalAsync(this.$route.params.rawsDocumentNumber)
              if (this.approval.rawsStatus !== 'L4 Manager Approval') {
                this.$router.push({ name: 'list-approvals' })
              }
            }
          }
        )
    },
    ...mapActions(useApprovalStore, [
      'fetchApprovalAsync',
      'resolveApprovalAsync'
    ])
  },
  async mounted() {
    this.isLoading = true
    await this.fetchApprovalAsync(this.$route.params.rawsDocumentNumber).then(
      (res) => {
        this.isLoading = false
      }
    )
  }
}
</script>

<style scoped></style>
